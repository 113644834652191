import { createContext, useContext } from 'react';
import { GetUserResponseProps } from 'api/types';

export type AuthContextProps = {
  user: GetUserResponseProps;
  setUser: (values: any) => void;
};

export const AuthContext = createContext<AuthContextProps>({
  user: {} as GetUserResponseProps,
  setUser: () => console.log('setUser'),
});

export const useAuth = (): Readonly<AuthContextProps> => useContext(AuthContext);
