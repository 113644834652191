// material-ui
import { Box, Button, styled } from '@mui/material';
// styles
import { primary } from 'themes/colors';

export const Wrapper = styled(Box)`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 12px 32px;
  background-color: ${primary[700]};
  box-shadow: ${({ theme }) => theme.cShadows.sm};

  ${({ theme }) => ({
    [theme.breakpoints.down('sm')]: {
      padding: '12px 8px 12px 16px',
    },
  })}
`;

export const MenuButton = styled(Button)`
  width: auto;
  min-width: 40px;
  height: 40px;

  svg {
    transition: stroke 0.15s ease-in-out;
  }

  &:hover {
    box-shadow: none;

    svg {
      stroke: ${primary[300]};
    }
  }
`;
