import React, { memo } from 'react';
// material-ui
import { Checkbox as MaterialCheckbox, FormControl, FormControlLabel } from '@mui/material';
// utils
import { areEqualFieldProps } from 'utils';
// icons
import { ReactComponent as CheckedIcon } from 'assets/icons/checkbox-checked.svg';
import { ReactComponent as UnCheckedIcon } from 'assets/icons/checkbox-unchecked.svg';
// types
import { CheckboxProps } from './types';

const Checkbox: React.FC<CheckboxProps> = ({ field, label, fullWidth, ...rest }) => {
  return (
    <FormControl fullWidth={fullWidth}>
      <FormControlLabel
        control={
          <MaterialCheckbox
            {...field}
            size='small'
            icon={<UnCheckedIcon />}
            checkedIcon={<CheckedIcon />}
            checked={!!field.value}
            disableRipple
            sx={{
              padding: '0 9px',
            }}
            {...rest}
          />
        }
        label={label}
        sx={{
          alignItems: 'flex-start',
          '& .MuiCheckbox-root': {
            mt: '4px',
          },
        }}
      />
    </FormControl>
  );
};

export default memo(Checkbox, areEqualFieldProps);
