import { getIn } from 'formik';

export const PASSWORD_REGEX = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[^ \w])/;

export const areEqualFieldProps = (prevProps: any, nextProps: any) =>
  getIn(prevProps.form.values, prevProps.field.name) === getIn(nextProps.form.values, nextProps.field.name) &&
  getIn(prevProps.form.touched, prevProps.field.name) === getIn(nextProps.form.touched, nextProps.field.name) &&
  getIn(prevProps.form.errors, prevProps.field.name) === getIn(nextProps.form.errors, nextProps.field.name) &&
  prevProps.options === nextProps.options &&
  prevProps.disabled === nextProps.disabled &&
  prevProps.exhaustiveDeps === nextProps.exhaustiveDeps &&
  prevProps.type === nextProps.type;

export const base64toBlob = (data: string) => {
  const bytes = atob(data);
  let length = bytes.length;
  const out = new Uint8Array(length);

  while (length--) {
    out[length] = bytes.charCodeAt(length);
  }

  return new Blob([out], { type: 'application/pdf' });
};

export const formatURL = (url: string) => {
  if (!url) return url;

  return url.match(/https?:\/\/(?:www\.)?[-a-zA-Z0-9@:%._+~#=]{1,256}/g) ? url : `https://www.ciaservices.com/${url}`;
};

export const removeHTMLTags = (string: string) => string.replaceAll(/<[^>]*>/g, '');

export const groupBy = <T extends Record<string, any>, K extends keyof T>(
  array: T[],
  key: K | { (obj: T): string },
): Record<string, T[]> => {
  const keyFn = key instanceof Function ? key : (obj: T) => obj[key];

  return array.reduce((objectsByKeyValue, obj) => {
    const value = keyFn(obj);
    objectsByKeyValue[value] = (objectsByKeyValue[value] || []).concat(obj);

    return objectsByKeyValue;
  }, {} as Record<string, T[]>);
};

export const convertBase64 = (file: File) => {
  return new Promise((resolve, reject) => {
    const fileReader = new FileReader();
    fileReader.readAsDataURL(file);

    fileReader.onload = () => {
      resolve(fileReader.result);
    };

    fileReader.onerror = (error) => {
      reject(error);
    };
  });
};

export const formatCurrency = (value: number) => {
  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
  });

  return formatter.format(value);
};

export const normalizeHTML = (string: string) => {
  const regex = /<a href="(.+?)">(.+?)<\/a>/g;
  const matches = string?.match(regex);

  if (matches) {
    matches.forEach((match) => {
      const link = match.match(/href="(.+?)"/);
      const text = match.match(/>(.+?)</);

      if (link && text) {
        string = string?.replace(match, `<a href="${link[1]}" target="_blank">${text[1]}</a>`);
      }
    });
  }
  return string;
};
