import React from 'react';
// material-ui
import LoadingButton from '@mui/lab/LoadingButton';
import { Alert, AlertTitle, Box, Stack, Typography } from '@mui/material';
import { useMutation } from '@tanstack/react-query';
import { useAuth } from 'context/AuthContext';
// libs
import { Field, Form, Formik } from 'formik';
import { useLocalStorage } from 'hooks/useLocalStorage';
import routes from 'routes';
// components
import { Input, PasswordInput } from 'components';
import { loginUserFn } from 'api/authApi';
import { LoginInputProps } from 'api/types';
import AuthLayout from 'Layouts/AuthLayout';
import { signInInitialValues, SignInSchema } from './constants';
// styles
import { StyledLink } from './styled';
import { grey } from 'themes/colors';
// types
import { ValuesProps } from './types';

const SignIn = () => {
  const { setUser } = useAuth();
  const [userData, setUserDataToLocalStorage] = useLocalStorage<any>('user', {});

  const device = window.navigator.userAgent;

  const {
    mutate: loginUser,
    isLoading,
    error,
  } = useMutation((userData: LoginInputProps) => loginUserFn(userData), {
    onSuccess: (data) => {
      setUser(data);
      setUserDataToLocalStorage({ token: data.token, appUserId: data.appUserId, accessLevel: data.accessLevel });
    },
  });

  const handleSubmit = ({ Login, Password }: ValuesProps) => {
    loginUser({ Login, Password, Device: device });
  };

  return (
    <AuthLayout title='Log in to your account' subtitle='Welcome back!'>
      <>
        <Box mb={1}></Box>

        {error && (
          <Alert severity='error' sx={{ mb: 3 }}>
            <>
              <AlertTitle>Error</AlertTitle>
              {error}
            </>
          </Alert>
        )}

        <Formik initialValues={signInInitialValues} onSubmit={handleSubmit} validationSchema={SignInSchema}>
          <Form>
            <Field cypress-id='signIn-login' name='Login' label='Login' placeholder='Enter Login' component={Input} />

            <Field
              cypress-id='signIn-password'
              name='Password'
              type='password'
              label='Password'
              placeholder='Enter Password'
              component={PasswordInput}
            />

            <Stack flexDirection='row' justifyContent='flex-end' mt='8px' mb='24px'>
              <StyledLink cypress-id='signIn-forgotPasswordLink' to={routes.auth.resetPassword}>
                Forgot password
              </StyledLink>
            </Stack>

            <LoadingButton
              cypress-id='signIn-button'
              loading={isLoading}
              type='submit'
              variant='contained'
              color='primary'
            >
              Sign in
            </LoadingButton>

            <Typography variant='body2' color={grey[500]} mt={3} textAlign='center'>
              Don’t have an account?
              <span>
                <StyledLink cypress-id='signIn-signUpLink' to={routes.auth.signUp}>
                  Sign up
                </StyledLink>
              </span>
            </Typography>
          </Form>
        </Formik>
      </>
    </AuthLayout>
  );
};

export default SignIn;
