import React from 'react';
import { Outlet } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import { useAuth } from 'context/AuthContext';
import FullScreenLoader from 'components/FullScreenLoader';
import { getUserFn } from 'api/authApi';
import SignIn from 'pages/SignIn';

const PrivateRoute = () => {
  const { user, setUser } = useAuth();
  const localStorageData = localStorage.getItem('user');
  const userData = localStorageData && JSON.parse(localStorageData);
  const isUser = user && user.appUserId;

  const { isLoading, isFetching } = useQuery(
    ['authUser'],
    () => getUserFn({ AppUserId: userData?.appUserId, Token: userData?.token }),
    {
      enabled: !!userData?.appUserId && !!userData?.token,
      onSuccess: (data) => {
        setUser(data);
      },
    },
  );

  if (isLoading && isFetching) {
    return <FullScreenLoader />;
  }

  return isUser ? <Outlet /> : <SignIn />;
};

export default PrivateRoute;
