import React, { memo } from 'react';
// material-ui
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import { FormControl, FormHelperText, FormLabel, InputAdornment, Typography } from '@mui/material';
// libs
import { getIn } from 'formik';
// utils
import { areEqualFieldProps } from 'utils';
import { StyledTextField } from './styled';
// styles
import { grey } from 'themes/colors';
// types
import { InputBaseProps } from './types';

const Input: React.FC<InputBaseProps> = ({
  disabled,
  multiline,
  placeholder,
  label,
  rows = 4,
  InputProps,
  helperText,
  type = 'text',
  adornmentdivider,
  field,
  form: { errors, touched },
  endAdornment,
  inputProps,
  ...props
}) => {
  const errorMessage = getIn(errors, field.name);
  const isError = errorMessage && getIn(touched, field.name);

  return (
    <FormControl fullWidth sx={{ mb: 2 }}>
      {label && (
        <FormLabel sx={{ mb: '6px' }}>
          <Typography variant='subtitle2' color={grey[900]}>
            {label}
          </Typography>
        </FormLabel>
      )}

      <StyledTextField
        {...field}
        {...props}
        type={type}
        adornmentdivider={adornmentdivider}
        placeholder={placeholder}
        disabled={disabled}
        multiline={multiline}
        rows={multiline && rows}
        InputProps={{
          endAdornment: (
            <InputAdornment position='end'>
              {endAdornment}

              {helperText && !multiline && !isError && <HelpOutlineIcon fontSize='small' />}
              {isError && !multiline && <ErrorOutlineIcon fontSize='small' color='error' />}
            </InputAdornment>
          ),
          ...(InputProps as Record<string, unknown>),
        }}
        inputProps={{
          autoComplete: 'new-password',
          form: {
            autoComplete: 'off',
          },
          ...inputProps,
        }}
        variant='outlined'
        error={isError}
        helperText={isError && errorMessage}
        size='small'
      />

      {helperText && <FormHelperText>{helperText}</FormHelperText>}
    </FormControl>
  );
};

export default memo(Input, areEqualFieldProps);
