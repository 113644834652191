import * as Yup from 'yup';
import { PASSWORD_REGEX } from 'utils';

export const signInInitialValues = {
  Login: '',
  Password: '',
  remember: false,
};

export const SignInSchema = Yup.object().shape({
  Login: Yup.string().required('Required'),
  Password: Yup.string()
    .required('Required')
    .matches(
      PASSWORD_REGEX,
      'Please make sure your password is min 8 characters and contains at least 1 uppercase letter, 1 lowercase letter, 1 special symbol and 1 digit',
    ),
});
