export default {
  home: '/',
  myProperty: {
    index: '/my-property',
    financialInfo: '/my-property/financial-information',
    complainceIssues: '/my-property/complaince-issues',
    improvementApps: '/my-property/improvement-apps',
    communications: '/my-property/communications',
    selling: '/my-property/selling',
  },
  auth: {
    signIn: '/',
    signUp: '/sign-up',
    resetPassword: '/reset-password',
  },
  publicDocuments: '/public-documents',
  associationDocuments: '/association-documents',
  communityInformation: '/community-information',
  deviceRequest: '/device-request',
  contactUs: '/contact-us',
  ciaServices: '/cia-services',
  boardMemberPortal: '/board-member-portal',
  keyPersonnel: '/key-personnel',
  allAboutMe: '/all-about-me',
  lookLikeAnAccount: '/look-like-an-account',
};
