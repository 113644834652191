import React from 'react';
// material-ui
import { Box, ListItem, ListItemButton, ListItemIcon } from '@mui/material';
// routes
import routes from 'routes';
// images
import { ReactComponent as CommunicationsIcon } from 'assets/icons/Communications.svg';
import { ReactComponent as ComplianceIcon } from 'assets/icons/Compliance.svg';
import { ReactComponent as FinancialIcon } from 'assets/icons/Financy.svg';
import { ReactComponent as ImprovementIcon } from 'assets/icons/Improvement.svg';
import { ReactComponent as SellingIcon } from 'assets/icons/Selling.svg';
// styles
import { StyledList, StyledListItemText, StyledNavLink } from './styled';
// types
import { PropertyNavProps } from './types';

const PropertyNav: React.FC<PropertyNavProps> = ({ direction = 'column' }) => {
  const isRow = direction === 'row';
  const navLinks = [
    {
      key: 'financialInformationLink',
      title: 'Financial Information',
      icon: <FinancialIcon />,
      route: routes.myProperty.financialInfo,
    },
    {
      key: 'complianceIssuesLink',
      title: 'Compliance Issues',
      icon: <ComplianceIcon />,
      route: routes.myProperty.complainceIssues,
    },
    {
      key: 'improvementApplicationsLink',
      title: 'Improvement Applications',
      icon: <ImprovementIcon />,
      route: routes.myProperty.improvementApps,
    },
    {
      key: 'communicationsLink',
      title: 'Communications',
      icon: <CommunicationsIcon />,
      route: routes.myProperty.communications,
    },
    {
      key: 'sellingRefinancingLink',
      title: 'Selling / Refinancing',
      icon: <SellingIcon />,
      route: routes.myProperty.selling,
    },
  ];

  return (
    <Box mt={3}>
      <StyledList
        sx={{
          flexDirection: isRow ? 'row' : 'column',
        }}
      >
        {navLinks.map(({ title, icon, route, key }, index) => (
          <StyledNavLink cypress-id={`myPropertyNav-${key}`} to={route} key={index}>
            <ListItem disablePadding>
              <ListItemButton
                sx={{
                  padding: isRow ? '7px' : '8px 16px',
                  justifyContent: 'center',
                }}
              >
                <ListItemIcon sx={{ minWidth: 'unset' }}>{icon}</ListItemIcon>

                {!isRow && <StyledListItemText primary={title} />}
              </ListItemButton>
            </ListItem>
          </StyledNavLink>
        ))}
      </StyledList>
    </Box>
  );
};

export default PropertyNav;
