// libs
import { Link } from 'react-router-dom';
// material-ui
import { Box, Divider as MaterialDivider, styled } from '@mui/material';
// styles
import { Wrapper as HeaderWrapper } from '../Header/styled';
import { primary } from 'themes/colors';

export const Wrapper = styled(Box)`
  position: absolute;
  background-color: ${primary[700]};
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 2;
  display: flex;
  flex-direction: column;
  overflow: auto;
`;

export const Header = styled(HeaderWrapper)`
  box-shadow: none;
`;

export const NavLinks = styled(Box)`
  margin: 16px 0;
`;

export const NavLink = styled(Link)`
  display: flex;
  align-items: center;
  font-size: 20px;
  line-height: 30px;
  color: #ffffff;
  text-decoration: none;
  padding: 8px 42px 8px 32px;
  transition: color 0.15s ease-in-out;

  svg {
    margin-left: 48px;
    transition: stroke 0.15s ease-in-out;
  }

  &:hover {
    color: ${primary[300]};

    svg {
      stroke: ${primary[300]};
    }
  }

  ${({ theme }) => ({
    [theme.breakpoints.down('sm')]: {
      padding: '8px 16px',
    },
  })}
`;

export const Divider = styled(MaterialDivider)`
  margin: 16px;
  border-color: ${primary[500]};
`;

export const Footer = styled(Box)`
  margin-top: auto;
  padding: 26px 55px;
`;
