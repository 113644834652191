// materail-ui
import { Button, styled } from '@mui/material';
// icons
import { grey, primary } from 'themes/colors';

export const StyledButton = styled(Button)`
  border-radius: 8px;
  border: 1px solid ${grey[200]};
  background-color: #ffffff;
  padding: 16px;
  flex-direction: column;
  align-items: flex-start;
  position: relative;
  height: auto;
  box-shadow: none;
  text-align: left;

  svg {
    position: absolute;
    top: 16px;
    right: 16px;
  }

  &:not(:last-of-type) {
    margin-bottom: 8px;
  }

  &:hover {
    box-shadow: none;
    background-color: ${primary[50]};
    border-color: ${primary[300]};
  }
`;
