import React, { memo, useCallback } from 'react';
// material-ui
import { Box, Stack } from '@mui/material';
import moment from 'moment';
// icons
import { ReactComponent as TopRightIcon } from 'assets/icons/topRightIcon.svg';
// styles
import { IconWrapper, SubTitle, Title } from './../../styled';
// types
import { CardProps } from './../../types';

const DefaultCard: React.FC<CardProps> = ({ id = '', date, title, handleClick }) => {
  const onHandleClick = useCallback(() => {
    handleClick?.(id);
  }, [handleClick, id]);

  return (
    <>
      <Box>
        <Title variant='subtitle2'>Date</Title>
        <SubTitle>{moment(date).format('L')}</SubTitle>
      </Box>

      <Stack flexDirection={'row'} alignItems='center' justifyContent={'space-between'}>
        <Box>
          <Title variant='subtitle2'>Title</Title>
          <SubTitle>{title}</SubTitle>
        </Box>

        <IconWrapper onClick={onHandleClick}>
          <TopRightIcon />
        </IconWrapper>
      </Stack>
    </>
  );
};

export default memo(DefaultCard);
