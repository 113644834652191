import React, { useCallback, useEffect, useState } from 'react';
// libs
import { Link, useLocation } from 'react-router-dom';
import NavigationMenu from '../NavigationMenu';
// icons
import { ReactComponent as LogotypeIcon } from 'assets/icons/logotype.svg';
import { ReactComponent as MenuIcon } from 'assets/icons/menu.svg';
// styles
import { MenuButton, Wrapper } from './styled';

const Header: React.FC = () => {
  const location = useLocation();
  const [isMenuOpen, setMenuOpen] = useState(false);

  const toggleMenuOpen = useCallback(() => {
    document.body.style.overflow = isMenuOpen ? '' : 'hidden';

    setMenuOpen(!isMenuOpen);
  }, [isMenuOpen]);

  useEffect(() => {
    if (isMenuOpen) {
      toggleMenuOpen();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  return (
    <Wrapper>
      <Link cypress-id='header-logotype' to='/'>
        <LogotypeIcon />
      </Link>

      <MenuButton cypress-id='header-menuButton' onClick={toggleMenuOpen}>
        <MenuIcon />
      </MenuButton>

      {isMenuOpen && <NavigationMenu handleClose={toggleMenuOpen} />}
    </Wrapper>
  );
};

export default Header;
