import { ciaServicesApiClient } from './axiosConfig';
import { RequestParams } from './types';

export const getDevicesApi = async (params: RequestParams) => {
  const response = await ciaServicesApiClient.get('ownerapp/AppGetDevices.php', {
    params,
  });

  // if string === error, we need array from response
  return typeof response.data === 'string' ? null : response.data;
};
