import React, { memo } from 'react';
// material-ui
import { FormControl, FormControlLabel } from '@mui/material';
// utils
import { areEqualFieldProps } from 'utils';
// style
import { StyledSwitch } from './styled';
// types
import { SwitchBaseProps } from './types';

const Switch: React.FC<SwitchBaseProps> = ({ field, label, disabled }) => {
  return (
    <FormControl>
      <FormControlLabel
        control={<StyledSwitch {...field} checked={field?.value} disableFocusRipple />}
        disabled={disabled}
        label={label}
        sx={{ ml: 0 }}
      />
    </FormControl>
  );
};

export default memo(Switch, areEqualFieldProps);
