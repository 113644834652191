// material-ui
import { styled, Switch } from '@mui/material';
import { grey, primary } from 'themes/colors';

export const StyledSwitch = styled(Switch)`
  width: 36px;
  height: 20px;
  padding: 0;
  margin-right: 8px;

  & .MuiSwitch-switchBase {
    padding: 0;
    margin: 0;
    transition-duration: 300ms;

    &.Mui-disabled {
      color: ${grey[50]};
    }

    &.Mui-checked {
      transform: translateX(16px);
      color: #fff;

      & + .MuiSwitch-track {
        background-color: ${primary[600]};
        opacity: 1;
        border: 0;
      }

      &.Mui-disabled + .MuiSwitch-track {
        opacity: 0.5;
      }
    }

    &.Mui-disabled + .MuiSwitch-track {
      opacity: 1;
    }

    &:hover {
      background-color: transparent;
    }
  }

  & .MuiSwitch-thumb {
    box-sizing: border-box;
    width: 16px;
    height: 16px;
    margin-top: 2px;
    margin-left: 2px;
    box-shadow: ${({ theme }) => theme.cShadows.sm};
  }

  & .MuiSwitch-track {
    border-radius: 13px;
    background-color: ${grey[100]};
    opacity: 1;
  }
`;
