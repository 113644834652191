import React, { memo, useState } from 'react';
// material-ui
import { IconButton } from '@mui/material';
// utils
import { areEqualFieldProps } from 'utils';
// components
import Input from '../Input';
// icons
import { ReactComponent as EyeIcon } from 'assets/icons/eye.svg';
import { ReactComponent as EyeOffIcon } from 'assets/icons/eye-off.svg';
// types
import { InputBaseProps } from '../Input/types';

const PasswordInput: React.FC<InputBaseProps> = (props) => {
  const [isShowPassword, setShowPassword] = useState(false);

  const togglePassword = () => {
    setShowPassword(!isShowPassword);
  };

  return (
    <Input
      {...props}
      type={isShowPassword ? 'text' : 'password'}
      endAdornment={
        <IconButton disableRipple onClick={togglePassword}>
          {isShowPassword ? <EyeOffIcon /> : <EyeIcon />}
        </IconButton>
      }
    />
  );
};

export default memo(PasswordInput, areEqualFieldProps);
