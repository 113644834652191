import React, { memo } from 'react';
// material-ui
import { Grid, Stack, Typography } from '@mui/material';
import moment from 'moment';
import { PaymentCardProps } from 'components/Card/types';
import { formatCurrency } from 'utils';
import { ReactComponent as PaymentPlanIcon } from 'assets/icons/paymentPlan.svg';
// styles
import { SubTitle, Title } from './../../styled';

const PaymentCard: React.FC<PaymentCardProps> = ({
  date,
  planStartingBalance,
  numberOfPayments,
  downPayment,
  monthlyPayment,
  planStatus,
}) => {
  return (
    <>
      <Stack flexDirection='row' justifyContent='center' mb={3}>
        <PaymentPlanIcon />
      </Stack>

      <Grid container spacing={2}>
        <Grid item xs={6}>
          <Title variant='subtitle2'>Open Date</Title>
          <SubTitle>{moment(date).format('L')}</SubTitle>
        </Grid>

        <Grid item xs={6}>
          <Title variant='subtitle2'>Starting Balance</Title>
          <SubTitle>{formatCurrency(Number(planStartingBalance))}</SubTitle>
        </Grid>

        <Grid item xs={6}>
          <Title variant='subtitle2'>Total Payments</Title>
          <SubTitle>{numberOfPayments}</SubTitle>
        </Grid>

        <Grid item xs={6}>
          <Title variant='subtitle2'>Down Payment</Title>
          <SubTitle>{formatCurrency(Number(downPayment))}</SubTitle>
        </Grid>

        <Grid item xs={6}>
          <Title variant='subtitle2'>Monthly Payment</Title>
          <Typography fontSize='30px' fontWeight='600'>
            {formatCurrency(Number(monthlyPayment))}
          </Typography>
        </Grid>

        <Grid item xs={6}>
          <Title variant='subtitle2'>Status</Title>
          <SubTitle>{planStatus}</SubTitle>
        </Grid>
      </Grid>
    </>
  );
};

export default memo(PaymentCard);
