import React, { useEffect, useState } from 'react';
// components
import { weatherIcon } from './components/WeatherIcons';
// utils
import { langText } from './utils/lang';
import openWeather from './utils/openWeather';
// styles
import {
  Background,
  Content,
  CurrentWeather,
  DetailItem,
  Details,
  LightFont,
  LoadingWrapper,
  Spinner,
  WeatherIcon,
  WeatherInset,
  WeatherWidgetWrapper,
  Wrapper,
} from './styled';
// types
import { WeatherWidgetProps } from './types';

const WeatherWidget: React.FC<WeatherWidgetProps> = ({
  tempUnit = 'C',
  windSpeedUnit = 'kmph',
  lang = 'en',
  apiKey,
  location,
  lat,
  lon,
}) => {
  const [_data, setData] = useState<any | undefined>();

  useEffect(() => {
    openWeather({ apiKey, location, lat, lon, lang, tempUnit, windSpeedUnit }).then((result) => {
      setData(result);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Wrapper>
      {!_data && (
        <LoadingWrapper>
          <Spinner />
        </LoadingWrapper>
      )}

      {_data && (
        <WeatherWidgetWrapper isNight={_data.isNight}>
          <Background isNight={_data.isNight as boolean} />

          <Content>
            <h2>{_data.location}</h2>
          </Content>

          <WeatherIcon isNight={_data.isNight as boolean}>
            <WeatherInset>{weatherIcon[_data.weather_type as keyof typeof weatherIcon]}</WeatherInset>
          </WeatherIcon>

          <CurrentWeather>
            <h1>
              {_data.temperature}
              <LightFont
                // eslint-disable-next-line no-prototype-builtins
                dangerouslySetInnerHTML={{ __html: _data.hasOwnProperty('units') ? _data.units.temp : '°' }}
              />
            </h1>

            <h3>{_data.weather_desc}</h3>
          </CurrentWeather>

          <Details>
            <DetailItem>
              <div>
                <h4>
                  {
                    // eslint-disable-next-line no-prototype-builtins
                    langText.hasOwnProperty(lang) && langText[lang].hasOwnProperty('Wind')
                      ? langText[lang].Wind
                      : langText['en'].Wind
                  }
                </h4>
              </div>

              <div>
                <h2>
                  {_data.wind}
                  <LightFont>
                    {
                      // eslint-disable-next-line no-prototype-builtins
                      _data.hasOwnProperty('units') ? _data.units.wind : ' '
                    }
                  </LightFont>
                </h2>
              </div>
            </DetailItem>

            <DetailItem>
              <div>
                <h4>
                  {
                    // eslint-disable-next-line no-prototype-builtins
                    langText.hasOwnProperty(lang) && langText[lang].hasOwnProperty('Humidity')
                      ? langText[lang].Humidity
                      : langText['en'].Humidity
                  }
                </h4>
              </div>

              <div>
                <h2>
                  {_data.humidity}
                  <LightFont>%</LightFont>
                </h2>
              </div>
            </DetailItem>

            <DetailItem>
              <div>
                <h4>
                  {
                    // eslint-disable-next-line no-prototype-builtins
                    langText.hasOwnProperty(lang) && langText[lang].hasOwnProperty('FeelsLike')
                      ? langText[lang].FeelsLike
                      : langText['en'].FeelsLike
                  }
                </h4>
              </div>

              <div>
                <h2>
                  {_data.feels_like}
                  <LightFont
                    // eslint-disable-next-line no-prototype-builtins
                    dangerouslySetInnerHTML={{ __html: _data.hasOwnProperty('units') ? _data.units.temp : '°' }}
                  />
                </h2>
              </div>
            </DetailItem>
          </Details>
        </WeatherWidgetWrapper>
      )}
    </Wrapper>
  );
};

export default WeatherWidget;
