import React from 'react';
import {
  FormControl,
  FormHelperText,
  FormLabel,
  MenuItem,
  Select as MaterialUISelect,
  Typography,
} from '@mui/material';
import { getIn } from 'formik';
import { grey } from 'themes/colors';
import { SelectFieldProps } from './types';

const Select: React.FC<SelectFieldProps> = ({
  label,
  helperText,
  field,
  form: { errors, touched },
  options,
  extractValue = (option: any) => option.value,
  extractLabel = (option: any) => option.label,
  ...rest
}) => {
  const errorMessage = getIn(errors, field.name);
  const isError = errorMessage && getIn(touched, field.name);

  const renderValue = (selected: unknown) =>
    extractLabel(options.find((item: unknown) => extractValue(item) === selected) || {});

  return (
    <FormControl fullWidth sx={{ mb: 2 }} error={isError} size='small'>
      {label && (
        <FormLabel sx={{ mb: '6px' }}>
          <Typography variant='subtitle2' color={grey[900]}>
            {label}
          </Typography>
        </FormLabel>
      )}

      <MaterialUISelect {...field} {...rest} renderValue={renderValue}>
        {options.map((option) => (
          <MenuItem key={extractValue(option)} value={extractValue(option)}>
            {extractLabel(option)}
          </MenuItem>
        ))}
      </MaterialUISelect>

      {helperText && !isError && <FormHelperText>{helperText}</FormHelperText>}
      {isError && <FormHelperText>{errorMessage}</FormHelperText>}
    </FormControl>
  );
};

export default Select;
