import React, { memo } from 'react';
import { Box } from '@mui/material';
import { TabPanelProps } from './types';

const TabPanel: React.FC<TabPanelProps> = ({ children, value, index }) => {
  return (
    <div role='tabpanel' hidden={value !== index} id={`tabpanel-${index}`} aria-labelledby={`tab-${index}`}>
      {value === index && <Box sx={{ pt: 3 }}>{children}</Box>}
    </div>
  );
};

export default memo(TabPanel);
