import React, { FC, memo } from 'react';
// material-ui
import { Typography } from '@mui/material';
// icons
import { ReactComponent as LogotypeIcon } from 'assets/icons/logotype-signIn.svg';
// styles
import { LoginHead, LoginInner, LoginWrapper } from './styled';
import { grey } from 'themes/colors';
// types
import { AuthLayoutProps } from './types';

const AuthLayout: FC<AuthLayoutProps> = ({ title, subtitle, children }) => {
  return (
    <LoginWrapper>
      <LoginInner>
        <LoginHead>
          <LogotypeIcon />

          <Typography variant='h5' fontWeight={600} mt='24px' mb='8px'>
            {title}
          </Typography>

          <Typography variant='body1' color={grey[500]}>
            {subtitle}
          </Typography>
        </LoginHead>

        {children}
      </LoginInner>
    </LoginWrapper>
  );
};

export default memo(AuthLayout);
