// materail-ui
import { Box, Button, Stack, styled, Typography } from '@mui/material';
// styles
import { grey, primary } from 'themes/colors';

export const CardWrapper = styled(Box)`
  display: flex;
  flex-direction: column;
  padding: 20px;
  gap: 10px;
  background: #ffffff;
  border: 1px solid;
  border-radius: 8px;

  &:not(:last-of-type) {
    margin-bottom: 8px;
  }
`;

export const Title = styled(Typography)`
  color: ${primary[500]};
  font-weight: 600;
  line-height: 20px;
  margin-bottom: 4px;
`;

export const SubTitle = styled(Typography)`
  color: ${grey[900]};
  font-size: 16px;
  font-weight: 500;
  line-height: 28px;
`;

export const IconWrapper = styled(Button)`
  flex: 0 0 44px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 12px;
  width: 44px;
  min-width: 44px;
  height: 44px;
  border-radius: 8px;
  background-color: ${primary[50]};
  cursor: pointer;
  transition: background-color 0.3s ease;

  &:hover {
    box-shadow: none;
    background-color: ${primary[100]};
  }
`;

export const Row = styled(Stack)`
  flex-direction: row;
  align-items: center;
  gap: 9px;
  & *:first-of-type {
    margin: 0;
    flex: 0.4;
  }
  & *:last-child {
    flex: 1;
  }
`;
