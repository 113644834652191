// materail-ui
import { Box, Button, Dialog, DialogContent, styled, Typography } from '@mui/material';
// styles
import { grey, primary } from 'themes/colors';

export const StyledDialog = styled(Dialog)`
  & .MuiPaper-root {
    width: 100%;
    max-width: 546px;
    margin: 32px 16px;
    border-radius: 12px;
    box-shadow: ${({ theme }) => theme.cShadows.xl};
    padding: 24px 0;
  }
`;

export const CloseButton = styled(Button)`
  position: absolute;
  top: 8px;
  right: 8px;
  width: auto;
  min-width: 40px;
  height: 40px;
  box-shadow: none;

  svg {
    width: 20px;
    height: 20px;
    stroke: ${primary[600]};
  }
`;

export const Header = styled(Box)<{ isCentered: boolean }>`
  padding: 0 16px;

  ${({ isCentered }) =>
    isCentered &&
    `
    display: flex;
    flex-direction: column;
    align-items: center;
  `}
`;

export const IconWrapper = styled(Box)`
  display: flex;
  margin-bottom: 14px;
`;

export const Title = styled(Typography)`
  line-height: 32px;
  font-weight: 600;
  color: ${grey[900]};
`;

export const Subtitle = styled(Typography)`
  line-height: 24px;
  color: ${grey[500]};
  text-align: center;
  margin-bottom: 16px;
`;

export const Content = styled(DialogContent)`
  padding: 0 16px;
`;

export const ActionsWrapper = styled(Box)`
  padding: 16px 16px 0;
`;
