import React, { useCallback, useState } from 'react';
// material-ui
import { Box } from '@mui/material';
import { useMutation } from '@tanstack/react-query';
// libs
import { useAuth } from 'context/AuthContext';
import { useLocalStorage } from 'hooks/useLocalStorage';
import { saveUserApi } from 'api/allAboutMe';
import { SaveUserParamsProps } from 'api/types';
import SwitchAccountModal from './components/SwitchAccountModal';
// icons
import { ReactComponent as ChevronDownIcon } from 'assets/icons/chevron-down.svg';
// styles
import { AccountInfo, Button, Label, Title, Value } from './styled';
import { SwitchAccountProps } from './types';

const SwitchAccount: React.FC<SwitchAccountProps> = ({ globalScope, label, tooltipTitle }) => {
  const { user, setUser } = useAuth();
  const [userData] = useLocalStorage<any>('user', {});
  const [isSwitchAccountOpen, setSwitchAccountOpen] = useState(false);
  const { mutate: saveUser } = useMutation((data: SaveUserParamsProps) => saveUserApi(data));

  const isShowSwitchButton = user.propertyCount > 1;
  const address = `${user?.defaultOwnerAppProperty?.streetAddress}, ${user?.defaultOwnerAppProperty?.city}, ${user?.defaultOwnerAppProperty?.state} ${user?.defaultOwnerAppProperty?.zipcode}`;

  const toggleSwitchAccountOpen = useCallback(() => setSwitchAccountOpen(!isSwitchAccountOpen), [isSwitchAccountOpen]);

  const handleSwitchAccount = useCallback(
    (selectedAccount?: string) => {
      const { appUserId, firstName, lastName } = user;
      toggleSwitchAccountOpen();
      setUser({
        ...user,
        defaultOwnerAppProperty: user?.properties.find(({ propertyId }) => propertyId === selectedAccount),
      });

      if (globalScope) {
        saveUser({
          Token: userData?.token,
          Id: appUserId,
          DefaultPropertyId: selectedAccount,
          FirstName: firstName,
          LastName: lastName,
        });
      }
    },
    [globalScope, saveUser, setUser, toggleSwitchAccountOpen, user, userData?.token],
  );

  return (
    <Box>
      <AccountInfo isShowSwitchButton={isShowSwitchButton}>
        <Title variant='h5'>My Account</Title>

        <Label variant='body1'>Account</Label>
        <Value cypress-id='switchAccount-account' variant='body1'>
          {user?.defaultOwnerAppProperty?.account || 'none'}
        </Value>

        <Label variant='body1'>Address</Label>
        <Value cypress-id='switchAccount-address' variant='body1'>
          {address || 'none'}
        </Value>
      </AccountInfo>

      {isShowSwitchButton && (
        <Button cypress-id='switchAccount-button' variant='contained' size='large' onClick={toggleSwitchAccountOpen}>
          {label || 'Switch Account'} <ChevronDownIcon />
        </Button>
      )}

      <SwitchAccountModal
        user={user}
        isOpen={isSwitchAccountOpen}
        tooltipTitle={tooltipTitle}
        handleSubmit={handleSwitchAccount}
        handleClose={toggleSwitchAccountOpen}
      />
    </Box>
  );
};

export default SwitchAccount;
