// material-ui
import { Box, styled, Typography } from '@mui/material';
// styles
import { primary } from 'themes/colors';

export const Wrapper = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: auto;
  padding: 48px 55px;
  background-color: ${primary[800]};
`;

export const Description = styled(Typography)`
  margin-top: 24px;
  text-align: center;
  color: ${primary[300]};
`;
