// materail-ui
import { Box, Divider, styled, Tooltip } from '@mui/material';
// styles
import { grey } from 'themes/colors';

export const CurrentAccountInfo = styled(Box)`
  border-radius: 8px;
  background-color: ${grey[50]};
  padding: 16px;
`;

export const SectionDivider = styled(Divider)`
  border-color: ${grey[200]};
  margin: 32px 0;
`;

export const TitleWrapper = styled(Box)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 16px;
`;

export const HelpTooltip = styled(Tooltip)`
  cursor: pointer;
  transition: stroke 0.15s ease-in-out;

  &:hover {
    stroke: ${grey[300]};
  }
`;

export const AccountsList = styled(Box)`
  margin-bottom: 8px;
`;
