import { Accordion, AccordionSummary, Button, styled } from '@mui/material';
import { grey, primary } from 'themes/colors';

export const FilterContainer = styled(Accordion)`
  border: 1px solid ${grey[300]};
  border-radius: 8px !important;
  &.Mui-expanded {
    background-color: ${grey[50]};
    & .MuiAccordionSummary-content {
      border-bottom: 1px solid ${grey[200]};
      padding-bottom: 24px;
    }
  }
`;

export const FilterHeader = styled(AccordionSummary)`
  & .MuiAccordionSummary-content {
    align-items: center;
    gap: 7px;
    justify-content: center;
  }
`;

export const ClearAllButton = styled(Button)`
  display: inline-block;
  min-width: unset;
  width: auto;
  margin-bottom: 24px;
  color: ${primary[500]};
  font-size: 16px;
`;
