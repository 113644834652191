import React, { memo } from 'react';
// icons
import { ReactComponent as ChevronDownIcon } from 'assets/icons/chevron-down.svg';
// styles
import { AccordionTitle, StyledAccordion, StyledAccordionDetails, StyledAccordionSummary } from './styled';
// types
import { AccordionProps } from './types';

const Accordion: React.FC<AccordionProps> = ({ id, title, children, expanded, defaultExpanded, handleChange }) => (
  <StyledAccordion
    elevation={0}
    expanded={expanded}
    id={`panel${id}a-header`}
    aria-controls={`panel${id}a-content`}
    onChange={handleChange(`panel${id}`)}
    defaultExpanded={defaultExpanded}
  >
    <StyledAccordionSummary expandIcon={<ChevronDownIcon />}>
      <AccordionTitle variant='h5'>{title}</AccordionTitle>
    </StyledAccordionSummary>

    <StyledAccordionDetails>{children}</StyledAccordionDetails>
  </StyledAccordion>
);

export default memo(Accordion);
