// material-ui
import { NavLink } from 'react-router-dom';
import { List, ListItemText, styled } from '@mui/material';
import { primary } from 'themes/colors';

export const StyledList = styled(List)`
  display: flex;
  gap: 8px;
`;

export const StyledListItemText = styled(ListItemText)`
  & .MuiListItemText-primary {
    font-weight: 500;
    font-size: 18px;
    color: ${primary[700]};
    margin-left: 24px;
  }
`;

export const StyledNavLink = styled(NavLink)`
  display: block;
  flex: 1;
  text-decoration: none;
  font-size: 18px;
  color: ${primary[700]};
  background: ${primary[50]};
  box-shadow: 0px 1px 2px rgba(37, 23, 2, 0.05);
  border-radius: 8px;
  transition: background 0.3s ease;
  &:hover {
    background: ${primary[100]};
  }
  &.active {
    background: ${primary[600]};
    & svg path {
      stroke: #fff;
    }
  }
`;
