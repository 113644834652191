import { Box, styled } from '@mui/material';

export const Wrapper = styled(Box)`
  height: 520px;
  width: 343px;
  display: flex;
  flex-direction: column;
  margin: 0;
  position: relative;
  margin: 16px auto 0;

  & * {
    font-family: 'Lato', sans-serif;
  }
`;

export const LoadingWrapper = styled(Box)`
  width: 100%;
  height: 100%;
  text-align: center;
  display: flex;
  justify-content: center;
`;

export const Spinner = styled(Box)`
  display: block;
  margin: auto;
  position: relative;
  border: 12px solid rgba(243, 243, 243, 0.5);
  border-top: 12px solid #3a3636;
  border-radius: 50%;
  width: 30%;
  padding-top: 30%;
  z-index: 1;
  float: left;
  animation: spin 2s linear infinite;

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;

export const WeatherWidgetWrapper = styled(Box)<{ isNight: boolean }>`
  height: 100%;
  width: 100%;
  position: relative;

  & h1,
  & h2,
  & h3,
  & h4,
  & h5,
  & h6 {
    color: white;
    text-align: center;
  }

  & h1 {
    font-size: 55px;
    margin-block-start: 0;
    margin-block-end: 0;
    padding: 0;
    font-weight: 900;

    span {
      display: inline-block;
      width: 0;
    }
  }

  & h2 {
    font-size: 30px;
    padding: 5%;
    margin-block-start: 0;
    margin-block-end: 0;
    font-weight: 900;
  }

  & h3 {
    font-size: 23px;
    margin-block-start: 0;
    margin-block-end: 0;
    font-weight: 400;
  }
`;

export const Background = styled(Box)<{ isNight: boolean }>`
  margin: 0;
  position: absolute;
  height: 100%;
  width: 100%;
  z-index: 0;
  border-radius: 30px;
  overflow: hidden;
  background: -webkit-linear-gradient(45deg, #9bf8f4, #6f7bf7);
  background: linear-gradient(45deg, #9bf8f4, #6f7bf7);

  &::after {
    position: absolute;
    top: 90%;
    left: 50%;
    transform: translate(-50%, -50%);
    content: '';
    width: 150%;
    height: 130%;
    margin: 0;
    background: -webkit-linear-gradient(45deg, #9bf8f4, #6f7bf7);
    background: linear-gradient(45deg, #9bf8f4, #6f7bf7);
    border-radius: 100%;
    float: left;
  }

  ${({ isNight }) =>
    isNight &&
    `
      background: -webkit-linear-gradient(45deg, #0e0e11, #383c47);
      background: linear-gradient(45deg, #0e0e11, #383c47);

      &::after {
        background: -webkit-linear-gradient(45deg, #0e0e11, #383c47);
        background: linear-gradient(45deg, #0e0e11, #383c47);
      }
  `}
`;

export const Content = styled(Box)`
  position: relative;
  height: 16%;
`;

export const WeatherIcon = styled(Box)<{ isNight: boolean }>`
  height: 37%;
  margin: 0;
  text-align: center;
  display: flex;
  flex-direction: column;

  svg,
  .shape {
    filter: drop-shadow(0 0 10px rgba(0, 0, 0, 0.15));
    max-width: 100%;
    max-height: 100%;
    height: auto;
    width: auto;
  }

  .clear {
    height: 90%;
  }

  .cloudy {
    all: initial;
    min-width: 60%;
    min-height: 60%;
    width: 90%;
    height: auto;
    max-height: 100%;
    max-width: 100%;
    text-align: center;
    margin: 0;
    -moz-margin-start: 0;
    -moz-margin-end: 0;

    .Sun {
      padding-left: 5%;
      padding-top: 5%;
      max-width: 35%;
      min-width: 30%;
      width: auto;
      height: 50%;
      top: 0;
      z-index: 1;
      position: absolute;
    }

    .Cloud {
      position: relative;
      height: 100%;
      z-index: 2;
      width: auto;
      -moz-margin-start: 10%;
      -moz-margin-end: 10%;
    }
  }

  .thunderstorm {
    margin: auto;
    height: auto;
    max-width: 100%;
    text-align: center;
    -moz-padding-start: 5%;
    -moz-padding-end: 5%;
    .Cloud {
      margin-inline: auto;
      width: 90%;
      z-index: 2;
      position: relative;
    }

    .thunder {
      width: 90%;
      text-align: center;
      margin-inline: auto;
      display: flex;
      justify-content: center;
    }

    .drop-shape {
      margin: 15%;
      margin-inline: 8%;
      width: 20%;
    }

    .drops {
      text-align: center;
      display: flex;
      justify-content: center;
      margin-top: -8%;
      width: 80%;
      margin-inline: auto;
      margin-right: 12%;

      .Flash {
        margin-top: -22%;
        margin-right: -10%;
      }
    }

    .Flash {
      margin-top: -30%;
      -moz-padding-start: 3%;
      -moz-padding-end: 3%;
      max-width: 35%;
      min-width: 30%;
      width: auto;
      height: 40%;
      top: 0;
      z-index: 1;
    }
  }

  .fog {
    text-align: center;
    width: 100%;
    height: 100%;

    .cloud-foggy {
      overflow: hidden;
      height: 55%;
    }

    .Cloud {
      text-align: center;
      margin-inline: auto;
      width: 65%;
      position: relative;
    }

    .Mist {
      padding: 6%;
      position: relative;
      display: block;

      svg {
        width: 75%;
      }
    }
  }

  .Mist {
    svg {
      fill: url(#fog-gradient);
    }
  }

  .Snowflake {
    svg {
      fill: url(#snowflake-gradient);
    }
  }

  .Cloud {
    svg {
      fill: url(#cloud-gradient);
    }
  }

  .Sun {
    height: 100%;

    svg {
      fill: url(#sun-gradient);
    }
  }

  .Flash {
    svg {
      fill: url(#thunder-gradient);
    }
  }

  #cloud-gradient {
    --color-stop: rgba(255, 255, 255, 0.65);
    --color-bot: rgba(243, 237, 237, 0.8);
  }
  #sun-gradient {
    --color-stop: rgba(236, 184, 0, 0.9);
    --color-bot: rgba(234, 121, 13, 0.9);
  }
  #thunder-gradient {
    --color-stop: rgba(234, 190, 13, 1);
    --color-bot: rgba(223, 248, 11, 1);
  }

  #snowflake-gradient {
    --color-stop: rgb(224, 224, 224);
    --color-bot: rgba(255, 255, 255);
  }

  #fog-gradient {
    --color-stop: rgba(243, 237, 237, 1);
    --color-bot: rgb(83, 194, 236);
  }

  .drop-shape {
    z-index: 1;
    width: 15%;
    text-align: center;
    position: relative;
    background-color: #00eaff;
    border-radius: 50% 0 50% 50%;
    transform: rotate(-30deg);
    float: left;
    margin-inline: 4%;
  }

  .drop-shape:before {
    content: '';
    float: left;
    padding-top: 100%;
  }

  ${({ isNight }) =>
    isNight &&
    `
      .Cloud {
        svg {
          fill: url(#cloud-night-gradient);
        }
      }
      .Sun {
        height: 100%;

        svg {
          fill: url(#moon-gradient);
        }
      }

      #cloud-night-gradient{
        --color-stop: rgba(255, 255, 255,  0.65);
        --color-bot: rgba(168, 164, 164, 0.9);
      }
      
      #moon-gradient {
        --color-stop: rgb(255, 255, 255);
        --color-bot: rgba(96, 96, 96, 0.84);
      }

      .drop-shape {
        background-color: #1e8cb4;
      }
  `}
`;

export const WeatherInset = styled(Box)`
  margin: 0;
  display: inline-block;
  position: relative;
  margin-block: auto;
  min-height: 40%;
  max-width: 90%;
  max-height: 100%;
  min-width: 80%;
  height: auto;
  width: auto;
  margin-inline: auto;
  padding-block: 5%;
  padding-inline: 10%;
  justify-content: center;
  resize: horizontal;
`;

export const CurrentWeather = styled(Box)`
  all: inherit;
  height: 25%;
  color: rgba(255, 255, 255, 0.7);
  position: relative;

  & h1 {
    padding: 0;

    span {
      font-weight: 400;
    }
  }
`;

export const LightFont = styled('span')`
  color: rgba(255, 255, 255, 0.7);
  position: relative;
  top: -0.25em;
  font-size: 80%;
`;

export const Details = styled(Box)`
  position: relative;
  height: 22%;
  width: 100%;
  flex-direction: column;
  margin: 0;
  display: table;

  & span {
    font-weight: 200;
    font-size: 20px;
  }
`;

export const DetailItem = styled(Box)`
  padding: 5px;
  height: 100%;
  width: 33.33%;
  display: table-cell;
  vertical-align: middle;
  text-align: center;

  & div {
    vertical-align: middle;
    text-align: center;
    display: flex;
    height: 50%;
    width: 100%;
  }

  & h4 {
    display: inline-block;
    vertical-align: middle;
    line-height: normal;
    font-weight: 400;
    font-size: 13px;
    width: 100%;
    margin: auto;
  }

  & h2 {
    display: inline-block;
    vertical-align: middle;
    font-size: 30px;
    text-align: center;
    width: 100%;
  }
`;
