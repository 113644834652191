// material-ui
import { Accordion, AccordionDetails, AccordionSummary, styled, Typography } from '@mui/material';
// styles
import { grey } from 'themes/colors';

export const StyledAccordion = styled(Accordion)`
  padding-bottom: 24px;
  border-radius: 0;

  &:not(:last-of-type) {
    border-bottom: 1px solid ${grey[200]};
    margin-bottom: 24px;
  }

  &::before {
    content: none;
  }
`;

export const StyledAccordionSummary = styled(AccordionSummary)`
  min-height: 32px;
  padding: 0;

  &.Mui-expanded {
    min-height: 32px;
  }

  & .MuiAccordionSummary-content {
    margin: 0;

    &.Mui-expanded {
      margin: 0;
    }
  }

  & .MuiAccordionSummary-expandIconWrapper svg {
    width: 24px;
    height: 24px;
    stroke: ${grey[900]};
  }
`;

export const AccordionTitle = styled(Typography)`
  font-weight: 600;
  line-height: 32px;
  color: ${grey[900]};
`;

export const StyledAccordionDetails = styled(AccordionDetails)`
  padding: 16px 0 0;
`;
