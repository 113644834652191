// material-ui
import { Box, CircularProgress } from '@mui/material';
// styles
import { Wrapper } from './styled';

const FullScreenLoader = () => {
  return (
    <Wrapper>
      <Box display='flex' alignItems='center' justifyContent='center' sx={{ height: '100%' }}>
        <CircularProgress size={35} />
      </Box>
    </Wrapper>
  );
};

export default FullScreenLoader;
