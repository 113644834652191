import React, { memo } from 'react';
// material-ui
import { Box, Stack } from '@mui/material';
import moment from 'moment';
// utils
import { formatCurrency } from 'utils';
// styles
import { SubTitle, Title } from '../../styled';
// types
import { CardProps } from '../../types';

const LiensCard: React.FC<CardProps> = ({ date, amount, number }) => {
  return (
    <>
      <Box>
        <Title variant='subtitle2'>Date</Title>
        <SubTitle>{moment(date).format('L')}</SubTitle>
      </Box>

      <Stack flexDirection={'row'} alignItems='center' justifyContent={'space-between'}>
        <Box flex={1}>
          <Title variant='subtitle2'> Amount</Title>
          <SubTitle>{formatCurrency(Number(amount))}</SubTitle>
        </Box>

        <Box flex={1}>
          <Title variant='subtitle2'>Number</Title>
          <SubTitle>{number}</SubTitle>
        </Box>
      </Stack>
    </>
  );
};

export default memo(LiensCard);
