import React from 'react';
// icons
import { ReactComponent as CheckboxCheckedIcon } from 'assets/icons/checkbox-circle-checked.svg';
import { ReactComponent as CheckboxUncheckedIcon } from 'assets/icons/checkbox-circle-unchecked.svg';
// styles
import { Label, Value } from '../../styled';
import { StyledButton } from './styled';
// types
import { AccountButtonProps } from './types';

const AccountButton: React.FC<AccountButtonProps> = ({ uniqueKey, selected, accountInfo, handleChange }) => {
  const address = `${accountInfo?.streetAddress}, ${accountInfo?.city}, ${accountInfo?.state} ${accountInfo?.zipcode}`;

  return (
    <StyledButton cypress-id={`account${uniqueKey}-button`} variant='outlined' disableRipple onClick={handleChange}>
      <Label variant='body1'>Account</Label>
      <Value cypress-id={`account${uniqueKey}-account`} variant='body1' sx={{ fontSize: 16, lineHeight: '24px' }}>
        {accountInfo.account}
      </Value>

      <Label variant='body1'>Address</Label>
      <Value cypress-id={`account${uniqueKey}-address`} variant='body1' sx={{ fontSize: 16, lineHeight: '24px' }}>
        {address}
      </Value>

      {selected ? <CheckboxCheckedIcon /> : <CheckboxUncheckedIcon />}
    </StyledButton>
  );
};

export default AccountButton;
