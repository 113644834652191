// material-ui
import { styled, TextField } from '@mui/material';
// styles
import { grey } from 'themes/colors';
// types
import { InputProps } from './types';

export const StyledTextField = styled(TextField)<InputProps>`
  & .MuiInputBase-root {
    padding-right: 10px;
  }

  ${({ adornmentdivider }) =>
    adornmentdivider &&
    `
    & .MuiInputAdornment-positionStart {
      position: absolute;
      width: 65px;
      height: 40px;
      left: 0;
      top: 0;
      bottom: 0;
      max-height: initial;
      display: flex;
      justify-content: center;
      border: 1px solid ${grey[300]};
      border-right: 0;
      margin: 0;
      border-top-left-radius: 8px;
      border-bottom-left-radius: 8px;
    },
    & .MuiOutlinedInput-notchedOutline {
      width: 254px;
      left: 65px;
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
    },
    & .MuiInputBase-input {
      padding-left: 70px;
    }
  `}
`;
