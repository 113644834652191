import axios from 'axios';

const BASE_URL_CIA_SERVICES = 'https://www.ciaservices.com';

export const apiClient = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,
});

export const ciaServicesApiClient = axios.create({
  baseURL: BASE_URL_CIA_SERVICES,
  headers: {
    'Content-Type': 'application/json',
  },
});

apiClient.defaults.headers.common['Content-Type'] = 'application/json';

apiClient.interceptors.response.use(
  (response) => {
    if (response.data.successStatus !== 0) {
      return Promise.reject(response.data.message);
    }

    return response;
  },
  async (error) => {
    return Promise.reject(error.message);
  },
);
