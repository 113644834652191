import { Box, styled } from '@mui/material';

export const Clouds = styled(Box)`
  all: initial;
  margin-block: auto;
  text-align: center;
  display: block;
  margin: auto;
  height: auto;
  max-width: 90%;
  -moz-margin-start: 10%;
  -moz-margin-end: 10%;
`;

export const IconWrapper = styled(Box)`
  &.fall {
    all: initial;
    display: block;
    height: 100%;
    width: 100%;
    text-align: center;

    .cloudy {
      min-width: 60%;
      min-height: 60%;
      width: 90%;
      height: auto;
      margin-bottom: 0;
      max-height: 100%;
      max-width: 100%;
      margin-inline: auto;
      text-align: center;
      display: block;
    }

    .Cloud {
      position: relative;
      height: 75%;
      z-index: 2;
      width: auto;
    }

    .drops {
      all: revert;
      width: 100%;
      text-align: center;
      margin-inline: auto;
      display: flex;
      justify-content: center;
      margin-top: 8%;
    }

    &.snow {
      .Snowflake {
        margin: 0;
        margin-inline: 4%;
        width: 18%;
        text-align: center;
        display: flex;
        justify-content: center;
      }

      .drops {
        margin-top: 5%;
      }

      &.rain {
        .drops {
          margin-top: 0;
        }

        .drop-shape {
          margin-top: 10%;
          width: 16%;
          margin-bottom: 2%;
        }

        .Snowflake {
          margin-top: 5%;
        }
      }
    }
  }
`;
