// material-ui
import { Link } from 'react-router-dom';
import { styled } from '@mui/material';
import { primary } from 'themes/colors';

export const StyledLink = styled(Link)`
  color: ${primary[500]};
  font-size: 14px;
  font-weight: 500;
  margin-left: 4px;
  cursor: pointer;
  text-decoration: none;
  &:hover {
    text-decoration: underline;
  }
`;
