// material-ui
import { Link } from 'react-router-dom';
import { Box, css, styled } from '@mui/material';
import { primary } from 'themes/colors';

const linkStyles = css`
  color: ${primary[500]};
  margin-left: 4px;
  cursor: pointer;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
`;

export const LoginWrapper = styled(Box)`
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 16px;
`;

export const LoginInner = styled(Box)`
  max-width: 546px;
  width: 100%;
`;

export const LoginHead = styled(Box)`
  text-align: center;
  margin-bottom: 32px;
`;

export const StyledLink = styled(Link)`
  font-size: 14px;
  font-weight: 500;

  ${linkStyles}
`;

export const MailLink = styled('a')`
  ${linkStyles}
`;
