import { apiClient } from './axiosConfig';
import {
  GetUserProps,
  GetUserResponseProps,
  LoginInputProps,
  LoginResponseProps,
  LookLikeAnAccountData,
  RegisterUserInputProps,
  RegisterUserResponseProps,
  ResetPasswordData,
  ResetPasswordResponse,
  SetNewPasswordData,
} from './types';

export const loginUserFn = async (user: LoginInputProps) => {
  const response = await apiClient.post<LoginResponseProps>('api/securityservice/AuthenticateAppUser', user);

  return response.data;
};

export const registerUserFn = async (data: RegisterUserInputProps) => {
  const response = await apiClient.post<RegisterUserResponseProps>('api/ownerappservice/AppRegisterUser', data);

  return response.data;
};

export const resetPassword = async (data: ResetPasswordData) => {
  const response = await apiClient.post<ResetPasswordResponse>('api/ownerappservice/AppResetPassword', {
    ...data,
  });

  return response.data;
};

export const setNewPassword = async (data: SetNewPasswordData) => {
  const response = await apiClient.post<ResetPasswordResponse>('api/ownerappservice/AppSetNewPassword', {
    ...data,
  });

  return response.data;
};

export const getUserFn = async (user: GetUserProps) => {
  const response = await apiClient.post<GetUserResponseProps>('api/ownerappservice/AppGetUserProfile', user);

  return response.data;
};

export const getLookLikeAnAccount = async (data: LookLikeAnAccountData) => {
  const response = await apiClient.post<GetUserResponseProps>('api/ownerappservice/AppLookLikeAnAccount', {
    ...data,
  });

  return response.data;
};
