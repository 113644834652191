export const grey = {
  25: '#FCFCFD',
  50: '#F9FAFB',
  100: '#F2F4F7',
  200: '#EAECF0',
  300: '#D0D5DD',
  400: '#98A2B3',
  500: '#667085',
  600: '#475467',
  700: '#344054',
  800: '#1D2939',
  900: '#101828',
};

export const primary = {
  25: '#FCFCFD',
  50: '#F5F7FE',
  100: '#D3DAEE',
  200: '#A1AAC0',
  300: '#828FAC',
  400: '#647397',
  500: '#455883',
  600: '#20376A',
  700: '#172A5B',
  800: '#101E4C',
  900: '#0A153D',
};

export const bronz = {
  25: '#FCFCFD',
  50: '#F2ECDE',
  100: '#E8DCBE',
  200: '#DECC9F',
  300: '#D3BC80',
  400: '#C9AC60',
  500: '#BF9C41',
  600: '#A4802F',
  700: '#896620',
  800: '#6E4E14',
  900: '#5B3C0C',
};

export const error = {
  25: '#FFFBFA',
  50: '#FEF3F2',
  100: '#FEE4E2',
  200: '#FECDCA',
  300: '#FDA29B',
  400: '#F97066',
  500: '#F04438',
  600: '#D92D20',
  700: '#B42318',
  800: '#912018',
  900: '#7A271A',
};

export const indigo = {
  50: '#EEF4FF',
  700: '#3538CD',
};

export const blueLight = {
  50: '#F0F9FF',
  700: '#026AA2',
};

export const pink = {
  50: '#FDF2FA',
  700: '#C11574',
};

export const orange = {
  50: '#FFF6ED',
  700: '#C4320A',
};
