import React, { memo, useCallback } from 'react';
// material-ui
import { Box, Stack } from '@mui/material';
import moment from 'moment';
// icons
import { ReactComponent as ViewIcon } from 'assets/icons/viewIcon.svg';
// styles
import { IconWrapper, SubTitle, Title } from './../../styled';
// types
import { CardProps } from './../../types';

const ViolationActivityCard: React.FC<CardProps> = ({ id = '', date, description, handleClick }) => {
  const onHandleClick = useCallback(() => {
    handleClick?.(id);
  }, [handleClick, id]);

  return (
    <>
      <Stack flexDirection='row' alignItems='center' justifyContent='space-between'>
        <Box>
          <Title variant='subtitle2'>Date</Title>
          <SubTitle>{moment(date).format('L')}</SubTitle>
        </Box>

        {!!id && (
          <Box>
            <Title variant='subtitle2' textAlign='center'>
              View
            </Title>

            <SubTitle>
              <IconWrapper onClick={onHandleClick}>
                <ViewIcon />
              </IconWrapper>
            </SubTitle>
          </Box>
        )}
      </Stack>

      <Box>
        <Title variant='subtitle2'>Description</Title>
        <SubTitle>{description}</SubTitle>
      </Box>
    </>
  );
};

export default memo(ViolationActivityCard);
