import React, { memo, useCallback } from 'react';
// material-ui
import { Box, Stack } from '@mui/material';
import moment from 'moment';
// icons
import { ReactComponent as TopRightIcon } from 'assets/icons/topRightIcon.svg';
// styles
import { IconWrapper, SubTitle, Title } from './../../styled';
// types
import { CardProps } from './../../types';

const CommunicationCard: React.FC<CardProps> = ({ id = '', date, description, communicationType, handleClick }) => {
  const onHandleClick = useCallback(() => {
    handleClick?.(id);
  }, [handleClick, id]);

  return (
    <>
      <Stack flexDirection={'row'} alignItems='center' justifyContent={'space-between'}>
        <Box>
          <Title variant='subtitle2'>Date</Title>
          <SubTitle>{moment(date).format('L')}</SubTitle>
        </Box>

        <Box>
          <Title variant='subtitle2'>Type</Title>
          <SubTitle>{communicationType}</SubTitle>
        </Box>
      </Stack>

      <Stack flexDirection={'row'} alignItems='center' justifyContent={'space-between'} gap={2}>
        <Box flex={1}>
          <Title variant='subtitle2'>Description</Title>
          <SubTitle>{description}</SubTitle>
        </Box>

        {id && (
          <IconWrapper cypress-id={`CommunicationCard${id}-button`} onClick={onHandleClick}>
            <TopRightIcon />
          </IconWrapper>
        )}
      </Stack>
    </>
  );
};

export default memo(CommunicationCard);
