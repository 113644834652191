import React, { memo, useCallback } from 'react';
// material-ui
import { Box, Stack } from '@mui/material';
import moment from 'moment';
// icons
import { ReactComponent as TopRightIcon } from 'assets/icons/topRightIcon.svg';
// styles
import { IconWrapper, SubTitle, Title } from './../../styled';
import { StatusLabel } from './styled';
// types
import { CardProps } from './../../types';

const ApplicationCard: React.FC<CardProps> = ({ id = '', date, title, type, status, handleClick }) => {
  const isPending = type === 'pending';

  const onHandleClick = useCallback(() => {
    handleClick?.(id);
  }, [handleClick, id]);

  return (
    <>
      <Stack flexDirection='row' alignItems='flex-start' justifyContent='space-between'>
        <Box>
          <Title variant='subtitle2'>{isPending ? 'Received' : 'Response'}</Title>
          <SubTitle>{moment(date).format('L')}</SubTitle>
        </Box>

        <StatusLabel status={status}>{status}</StatusLabel>
      </Stack>

      <Stack flexDirection='row' alignItems='center' justifyContent='space-between' gap={2}>
        <Box>
          <Title variant='subtitle2'>Title</Title>
          <SubTitle>{title}</SubTitle>
        </Box>

        <IconWrapper onClick={onHandleClick}>
          <TopRightIcon />
        </IconWrapper>
      </Stack>
    </>
  );
};

export default memo(ApplicationCard);
