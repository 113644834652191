// material-ui
import { styled, Tabs } from '@mui/material';
import { grey, primary } from 'themes/colors';

export const StyledTabs = styled(Tabs)`
  & .MuiTabs-indicator {
    width: 0px !important;
  }

  & .MuiTab-root {
    color: ${grey[500]};
    transition: border-bottom 0.12s ease-in-out;
  }

  & .MuiTab-root.Mui-selected {
    color: ${grey[800]};
    border-bottom: 2px solid ${primary[600]};
  }

  ${({ variant }) =>
    variant === 'standard' &&
    `
      & .MuiTabs-flexContainer {
        flex-wrap: wrap;
      }
  `}
`;
