import React from 'react';
// materia-ui
import { FormControl, InputLabel, MenuItem, Select as MaterialUISelect } from '@mui/material';
// types
import { SelectProps } from './types';

const SimpleSelect: React.FC<SelectProps> = ({
  value,
  label,
  options,
  handleChange,
  extractValue = (option: any) => option.value,
  extractLabel = (option: any) => option.label,
}) => {
  return (
    <FormControl fullWidth>
      {!value && <InputLabel shrink={false}>{label}</InputLabel>}

      <MaterialUISelect value={value} onChange={handleChange}>
        {options.map((option) => (
          <MenuItem key={extractValue(option)} value={extractValue(option)}>
            {extractLabel(option)}
          </MenuItem>
        ))}
      </MaterialUISelect>
    </FormControl>
  );
};

export default SimpleSelect;
