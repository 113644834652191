import React from 'react';
import { Box, Divider, Stack, Typography } from '@mui/material';
import { useAuth } from 'context/AuthContext';
import { ReactComponent as AccountIcon } from 'assets/icons/account.svg';
import { ReactComponent as LocationIcon } from 'assets/icons/location.svg';

const DefaultPropertyData = () => {
  const { user } = useAuth();

  return (
    <>
      <Stack flexDirection='row' gap={3} mt={2} mb={2}>
        <Box display='flex' alignItems='center'>
          <AccountIcon />

          <Typography variant='subtitle2' ml={1} fontSize='16px'>
            {user?.defaultOwnerAppProperty?.account}
          </Typography>
        </Box>

        <Box display='flex' alignItems='center'>
          <LocationIcon />

          <Typography variant='subtitle2' ml={1} fontSize='16px'>
            {user?.defaultOwnerAppProperty?.streetAddress}
          </Typography>
        </Box>
      </Stack>

      <Divider />
    </>
  );
};

export default DefaultPropertyData;
