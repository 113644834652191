import axios from 'axios';

function doRequest(opts: any): Promise<any> {
  return new Promise((resolve, reject) => {
    try {
      axios.get(opts.url, opts.body).then((response) => resolve(response.data));
    } catch (error) {
      reject(error);
    }
  });
}

function request(opts: any) {
  return doRequest(opts)
    .then(function (response) {
      return response;
    })
    .catch(function (reason: any) {
      const message = 'Error requesting: ' + reason.message;
      const err = Error(message);

      throw err;
    });
}

export default request;
