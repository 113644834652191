import React, { memo } from 'react';
// material-ui
import { FormControl, FormHelperText, FormLabel, Typography } from '@mui/material';
// libs
import { getIn } from 'formik';
import MuiPhoneNumber from 'material-ui-phone-number';
// styles
import { grey } from 'themes/colors';
// types
import { InputPhoneProps } from './types';

const InputPhone: React.FC<InputPhoneProps> = ({
  label,
  helperText,
  field,
  form: { errors, touched, setFieldValue },
  ...props
}) => {
  const errorMessage = getIn(errors, field.name);
  const isError = errorMessage && getIn(touched, field.name);

  const handleChange = (value: string) => setFieldValue(field.name, value);

  return (
    <FormControl fullWidth sx={{ mb: 2 }}>
      {label && (
        <FormLabel sx={{ mb: '6px' }}>
          <Typography variant='subtitle2' color={grey[900]}>
            {label}
          </Typography>
        </FormLabel>
      )}

      <MuiPhoneNumber
        {...field}
        {...props}
        size='small'
        variant='outlined'
        defaultCountry='us'
        disableAreaCodes
        onChange={handleChange}
        error={isError}
        countryCodeEditable={false}
        helperText={isError && errorMessage}
      />

      {helperText && <FormHelperText>{helperText}</FormHelperText>}
    </FormControl>
  );
};

export default memo(InputPhone);
