import React from 'react';
// components
import * as WeatherIcon from '../IconObjects';
// styles
import { IconWrapper } from '../styled';

const Clouds = () => {
  return (
    <div className='clouds'>
      <WeatherIcon.Cloud />
    </div>
  );
};

const LightRain = () => {
  return (
    <IconWrapper className='fall rain'>
      <div className='cloudy'>
        <WeatherIcon.Sun />
        <WeatherIcon.Cloud />
      </div>
      <div className='drops'>
        <div className='shape drop-shape' />
      </div>
    </IconWrapper>
  );
};

const ModerateRain = () => {
  return (
    <IconWrapper className='fall rain'>
      <div className='cloudy'>
        <WeatherIcon.Sun />
        <WeatherIcon.Cloud />
      </div>
      <div className='drops'>
        <div className='shape drop-shape' />
        <div className='shape drop-shape' />
      </div>
    </IconWrapper>
  );
};

const Rain = () => {
  return (
    <IconWrapper className='fall rain'>
      <div className='cloudy'>
        <WeatherIcon.Sun />
        <WeatherIcon.Cloud />
      </div>
      <div className='drops'>
        <div className='shape drop-shape' />
        <div className='shape drop-shape' />
        <div className='shape drop-shape' />
      </div>
    </IconWrapper>
  );
};

const LightShowerRain = () => {
  return (
    <IconWrapper className='fall rain'>
      <WeatherIcon.Cloud />
      <div className='drops'>
        <div className='shape drop-shape' />
      </div>
    </IconWrapper>
  );
};

const ShowerRain = () => {
  return (
    <IconWrapper className='fall rain'>
      <WeatherIcon.Cloud />
      <div className='drops'>
        <div className='shape drop-shape' />
        <div className='shape drop-shape' />
        <div className='shape drop-shape' />
      </div>
    </IconWrapper>
  );
};

const Cloudy = () => {
  return (
    <div className='cloudy'>
      <WeatherIcon.Sun />
      <WeatherIcon.Cloud />
    </div>
  );
};

const Clear = () => {
  return (
    <div className='clear'>
      <WeatherIcon.Sun />
    </div>
  );
};

const Thunderstorm = () => {
  return (
    <div className='thunderstorm'>
      <WeatherIcon.Cloud />
      <div className='thunder'>
        <WeatherIcon.Flash />
      </div>
    </div>
  );
};

const ThunderstormRain = () => {
  return (
    <div className='thunderstorm'>
      <WeatherIcon.Cloud />
      <div className='drops'>
        <div className='shape drop-shape' />
        <WeatherIcon.Flash />
        <div className='shape drop-shape' />
      </div>
    </div>
  );
};

const LightSnow = () => {
  return (
    <IconWrapper className='fall snow'>
      <div className='cloudy'>
        <WeatherIcon.Sun />
        <WeatherIcon.Cloud />
      </div>
      <div className='drops'>
        <WeatherIcon.Snowflake />
      </div>
    </IconWrapper>
  );
};

const HeavySnow = () => {
  return (
    <IconWrapper className='fall snow'>
      <div className='cloudy'>
        <WeatherIcon.Sun />
        <WeatherIcon.Cloud />
      </div>
      <div className='drops'>
        <WeatherIcon.Snowflake />
        <WeatherIcon.Snowflake />
      </div>
    </IconWrapper>
  );
};

const Snow = () => {
  return (
    <IconWrapper className='fall snow'>
      <div className='cloudy'>
        <WeatherIcon.Sun />
        <WeatherIcon.Cloud />
      </div>
      <div className='drops'>
        <WeatherIcon.Snowflake />
        <WeatherIcon.Snowflake />
        <WeatherIcon.Snowflake />
      </div>
    </IconWrapper>
  );
};

const LightShowerSnow = () => {
  return (
    <IconWrapper className='fall snow'>
      <WeatherIcon.Cloud />
      <div className='drops'>
        <WeatherIcon.Snowflake />
      </div>
    </IconWrapper>
  );
};

const ShowerSnow = () => {
  return (
    <IconWrapper className='fall snow'>
      <WeatherIcon.Cloud />
      <div className='drops'>
        <WeatherIcon.Snowflake />
        <WeatherIcon.Snowflake />
        <WeatherIcon.Snowflake />
      </div>
    </IconWrapper>
  );
};

const FreezingRain = () => {
  return (
    <IconWrapper className='fall rain snow'>
      <WeatherIcon.Cloud />
      <div className='drops'>
        <div className='shape drop-shape' />
        <WeatherIcon.Snowflake />
        <div className='shape drop-shape' />
      </div>
    </IconWrapper>
  );
};

const SnowAndRain = () => {
  return (
    <IconWrapper className='fall snow rain'>
      <WeatherIcon.Cloud />
      <div className='drops'>
        <WeatherIcon.Snowflake />
        <div className='shape drop-shape' />
        <WeatherIcon.Snowflake />
      </div>
    </IconWrapper>
  );
};

const Fog = () => {
  return (
    <div className='fog'>
      <div className='cloud-foggy'>
        <WeatherIcon.Cloud />
      </div>
      <WeatherIcon.Mist />
    </div>
  );
};

export const weatherIcon = {
  Clear: <Clear />,
  Clouds: <Clouds />,
  Cloudy: <Cloudy />,
  Rain: <Rain />,
  LightRain: <LightRain />,
  ModerateRain: <ModerateRain />,
  ShowerRain: <ShowerRain />,
  LightShowerRain: <LightShowerRain />,
  Snow: <Snow />,
  LightSnow: <LightSnow />,
  HeavySnow: <HeavySnow />,
  ShowerSnow: <ShowerSnow />,
  LightShowerSnow: <LightShowerSnow />,
  Thunderstorm: <Thunderstorm />,
  ThunderstormRain: <ThunderstormRain />,
  FreezingRain: <FreezingRain />,
  SnowAndRain: <SnowAndRain />,
  Fog: <Fog />,
};
