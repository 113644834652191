// material-ui

import { Box, Button, styled } from '@mui/material';
import { grey, primary } from 'themes/colors';

export const DropzoneContainer = styled(Box)`
  background: ${primary[50]};
  border-radius: 8px;
  padding: 16px;
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
`;

export const File = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 8px;
  padding: 16px;
  border: 1px solid ${grey[200]};
`;

export const DeleteButton = styled(Button)`
  min-width: unset;
  width: 35px;
  height: 35px;
  box-shadow: none;
`;
