import React, { FC, useState } from 'react';
import { GetUserResponseProps } from 'api/types';
import { AuthContext } from './AuthContext';

interface AuthProviderProps {
  children: React.ReactNode;
}

export const AuthProvider: FC<AuthProviderProps> = ({ children }) => {
  const [user, setUser] = useState<GetUserResponseProps>({} as GetUserResponseProps);

  return <AuthContext.Provider value={{ user, setUser }}>{children}</AuthContext.Provider>;
};
