import React, { memo } from 'react';
// material-ui
import { Button, Stack, Typography } from '@mui/material';
// icons
import { ReactComponent as ErrorIcon } from 'assets/icons/error.svg';
import { ReactComponent as SuccessIcon } from 'assets/icons/success.svg';
// types
import { StatusProps } from './types';

const Status: React.FC<StatusProps> = ({ status, title, description, handleClose }) => {
  const isSuccess = status === 'success';

  if (!status) {
    return null;
  }

  return (
    <Stack alignItems='center'>
      {isSuccess ? <SuccessIcon /> : <ErrorIcon />}

      <Typography variant='h6' fontWeight='500' sx={{ mt: 1, mb: 1 }}>
        {title}
      </Typography>

      <Typography variant='body2' fontSize='14px' sx={{ mb: 3 }}>
        {description}
      </Typography>

      <Button variant='contained' color={isSuccess ? 'primary' : 'error'} onClick={handleClose}>
        OK
      </Button>
    </Stack>
  );
};

export default memo(Status);
