// material-ui
import { Box, Button, styled } from '@mui/material';

export const Wrapper = styled(Box)`
  min-height: 100vh;
  display: flex;
  flex-direction: column;

  & main {
    width: 100%;
    max-width: 546px;
    margin: 0 auto;
    padding: 32px 16px;
    flex: 1;
    display: flex;
    flex-direction: column;
  }
`;

export const PWAButton = styled(Button)`
  min-width: unset;
  width: auto;
`;
