import React from 'react';
// libs
import moment from 'moment';
// icons
import { ReactComponent as LogotypeIcon } from 'assets/icons/logotype-footer.svg';
// styles
import { Description, Wrapper } from './styled';

const Footer: React.FC = () => (
  <Wrapper>
    <LogotypeIcon />

    <Description>© 2021-{moment().year()} C.I.A. Services Inc. All rights reserved.</Description>
  </Wrapper>
);

export default Footer;
