import React, { memo, useCallback, useState } from 'react';
// libs
import { FileWithPath, useDropzone } from 'react-dropzone';
// material-ui
import { Box, Stack, Typography } from '@mui/material';
// icons
import { ReactComponent as DeleteIcon } from 'assets/icons/deleteIcon.svg';
import { ReactComponent as UploadIcon } from 'assets/icons/uploadIcon.svg';
// styles
import { DeleteButton, DropzoneContainer, File } from './styled';
// types
import { DragAndDropProps } from './types';

const DragAndDropZone: React.FC<DragAndDropProps> = ({ files, handleDrop }) => {
  const [error, setError] = useState('');

  const bytesToMegaBytes = (bytes: number) => (bytes / (1024 * 1024)).toFixed(2);

  const onDrop = useCallback(
    (files: File[], fileRejections: any) => {
      if (fileRejections.length) {
        const isTooLarge = fileRejections[0].errors[0].code === 'file-too-large';
        const errorMessage = isTooLarge ? 'File is larger than 5MB' : fileRejections[0].errors[0].message;

        setError(errorMessage);
      } else {
        setError('');
        handleDrop(files);
      }
    },
    [handleDrop],
  );

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: {
      'image/png': ['.png'],
      'image/jpeg': [],
      'application/pdf': [],
    },
    maxSize: 5000000,
  });

  const handleRemoveFile = useCallback(() => {
    handleDrop([]);
  }, [handleDrop]);

  return (
    <Box mb={2}>
      <div className='container'>
        <DropzoneContainer {...getRootProps({ className: 'dropzone' })}>
          <UploadIcon />
          <input cypress-id='provideAnUpdate-file' {...getInputProps()} />

          <Typography fontSize='14px' fontWeight='500' sx={{ mt: 2 }}>
            Click to upload
          </Typography>

          <Typography fontSize='12px'>PNG, JPG or PDF (Max size 5MB)</Typography>
        </DropzoneContainer>

        {error && (
          <Typography color='error' sx={{ mt: 1 }}>
            {error}
          </Typography>
        )}

        <Stack gap={1} mt={1}>
          {files.map((file: FileWithPath) => (
            <File key={file.path}>
              <Box>
                <Typography>{file.path}</Typography>
                <Typography>{bytesToMegaBytes(file.size)} MB</Typography>
              </Box>

              <DeleteButton onClick={handleRemoveFile}>
                <DeleteIcon />
              </DeleteButton>
            </File>
          ))}
        </Stack>
      </div>
    </Box>
  );
};

export default memo(DragAndDropZone);
