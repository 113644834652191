import React, { useEffect, useState } from 'react';
// libs
import { Outlet } from 'react-router-dom';
import { Stack } from '@mui/material';
import { Modal } from 'components';
// components
import Footer from './components/Footer';
import Header from './components/Header';
// styles
import { PWAButton, Wrapper } from './styled';

const Layout: React.FC = () => {
  const [installable, setInstallable] = useState(false);
  const [deferredPrompt, setDeferredPrompt] = useState<any>();

  useEffect(() => {
    window.addEventListener('beforeinstallprompt', (e: any) => {
      // Prevent the mini-infobar from appearing on mobile
      e.preventDefault();
      // Stash the event so it can be triggered later.
      setDeferredPrompt(e);
      // Update UI notify the user they can install the PWA
      setInstallable(true);
    });
  }, []);

  const handleInstallPWA = (e: any) => {
    // Hide the app provided install promotion
    setInstallable(false);
    // Show the install prompt
    deferredPrompt.prompt();
    // Wait for the user to respond to the prompt
    deferredPrompt.userChoice.then((choiceResult: any) => {
      if (choiceResult.outcome === 'accepted') {
        console.log('User accepted the install prompt');
      } else {
        console.log('User dismissed the install prompt');
      }
    });
  };

  const handleCancelInstallPWA = () => {
    setInstallable(false);
  };

  return (
    <>
      <Wrapper>
        <Header />
        <main>
          <Outlet />
        </main>
        <Footer />
      </Wrapper>

      <Modal
        open={installable}
        handleClose={() => setInstallable(false)}
        title='Install My RedDog App'
        subtitle='Do you want to add My RedDog to your device as an app?'
      >
        <Stack flexDirection='row' justifyContent='flex-end' gap={3} mt={2} mb={1}>
          <PWAButton variant='outlined' onClick={handleCancelInstallPWA}>
            Skip
          </PWAButton>

          <PWAButton variant='contained' onClick={handleInstallPWA}>
            Accept
          </PWAButton>
        </Stack>
      </Modal>
    </>
  );
};

export default Layout;
