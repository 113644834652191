import React, { memo } from 'react';
// icons
import { ReactComponent as CloseIcon } from 'assets/icons/close.svg';
// styles
import { ActionsWrapper, CloseButton, Content, Header, IconWrapper, StyledDialog, Subtitle, Title } from './styled';
// types
import { ModalProps } from './types';

const Modal: React.FC<ModalProps> = ({ open, icon, title, subtitle, children, actions, handleClose }) => (
  <StyledDialog open={open} onClose={handleClose}>
    <Header isCentered={!!icon}>
      {!!icon && <IconWrapper>{icon}</IconWrapper>}

      {!!title && (
        <Title variant='h5' sx={{ mb: subtitle ? '8px' : '24px' }}>
          {title}
        </Title>
      )}

      {!!subtitle && <Subtitle variant='subtitle1'>{subtitle}</Subtitle>}

      <CloseButton onClick={handleClose}>
        <CloseIcon />
      </CloseButton>
    </Header>

    <Content>{children}</Content>

    {!!actions && <ActionsWrapper>{actions}</ActionsWrapper>}
  </StyledDialog>
);

export default memo(Modal);
