import React, { memo, useState } from 'react';
// materail-ui
import { Box, Button } from '@mui/material';
import Modal from 'components/Modal';
import AccountButton from '../AccountButton';
// icons
import { ReactComponent as HelpIcon } from 'assets/icons/help.svg';
// styles
import { Label, Title, Value } from '../../styled';
import { AccountsList, CurrentAccountInfo, HelpTooltip, SectionDivider, TitleWrapper } from './styled';
// types
import { SwitchAccountModalProps } from './types';

const SwitchAccountModal: React.FC<SwitchAccountModalProps> = ({
  isOpen,
  user,
  tooltipTitle,
  handleSubmit,
  handleClose,
}) => {
  const [selectedAccount, setSelectedAccount] = useState(user?.defaultOwnerAppProperty?.propertyId);

  const address = `${user?.defaultOwnerAppProperty?.streetAddress}, ${user?.defaultOwnerAppProperty?.city}, ${user?.defaultOwnerAppProperty?.state} ${user?.defaultOwnerAppProperty?.zipcode}`;

  return (
    <Modal
      title='Switch Account'
      open={isOpen}
      handleClose={handleClose}
      actions={
        <Button
          cypress-id='switchAccount-switchButton'
          variant='contained'
          size='large'
          onClick={() => handleSubmit(selectedAccount)}
        >
          Switch
        </Button>
      }
    >
      <Box>
        <Title variant='h6' sx={{ lineHeight: '30px' }}>
          Current Account
        </Title>

        <CurrentAccountInfo>
          <Label variant='body1'>Account</Label>
          <Value cypress-id='switchAccountModal-account' variant='body1' sx={{ fontSize: 16, lineHeight: '24px' }}>
            {user?.defaultOwnerAppProperty?.account || 'none'}
          </Value>

          <Label variant='body1'>Property Address</Label>
          <Value cypress-id='switchAccountModal-address' variant='body1' sx={{ fontSize: 16, lineHeight: '24px' }}>
            {address || 'none'}
          </Value>
        </CurrentAccountInfo>

        <SectionDivider />

        <TitleWrapper>
          <Title variant='h6' sx={{ lineHeight: '30px', margin: 0 }}>
            Switch To
          </Title>

          <HelpTooltip
            title={
              tooltipTitle ||
              'This will change the account that appears each time you log in. You can temporarily switch to a different account at any time from the home page.'
            }
            placement='top-end'
          >
            <HelpIcon />
          </HelpTooltip>
        </TitleWrapper>

        <AccountsList>
          {user?.properties.map(({ propertyId, ...accountInfo }, index) => (
            <AccountButton
              key={propertyId}
              selected={selectedAccount === propertyId}
              handleChange={() => setSelectedAccount(propertyId)}
              accountInfo={accountInfo}
              uniqueKey={index}
            />
          ))}
        </AccountsList>
      </Box>
    </Modal>
  );
};

export default memo(SwitchAccountModal);
