// material-ui
import { Container, styled } from '@mui/material';

export const Wrapper = styled(Container)`
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: #ffffff;
`;
