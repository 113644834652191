import React from 'react';
import { Box, CircularProgress } from '@mui/material';

const SectionLoader = () => {
  return (
    <Box display='flex' justifyContent='center' alignItems={'center'}>
      <CircularProgress size={30} thickness={2} />
    </Box>
  );
};

export default SectionLoader;
