import React, { memo } from 'react';
// material-ui
import { Box, Stack } from '@mui/material';
import moment from 'moment';
// utils
import { formatCurrency } from 'utils';
// styles
import { Row, SubTitle, Title } from './../../styled';
// types
import { CardProps } from './../../types';

const DefaultCard: React.FC<CardProps> = ({ date, description, invoice, due }) => {
  return (
    <>
      <Row>
        <Title variant='subtitle2'>Date</Title>
        <SubTitle>{moment(date).format('L')}</SubTitle>
      </Row>

      <Row>
        <Title variant='subtitle2'>Description</Title>
        <SubTitle>{description}</SubTitle>
      </Row>

      <Row>
        <Title variant='subtitle2'>{'Amount'}</Title>
        <SubTitle>{formatCurrency(Number(invoice))}</SubTitle>
      </Row>

      <Row>
        <Title variant='subtitle2'>{'Balance'}</Title>
        <SubTitle>{formatCurrency(Number(due))}</SubTitle>
      </Row>
    </>
  );
};

export default memo(DefaultCard);
