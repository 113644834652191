import React, { memo } from 'react';
// material-ui
import { Box, Tab } from '@mui/material';
// libs
import camelCase from 'lodash.camelcase';
// styles
import { StyledTabs } from './styled';
// types
import { TabsProps } from './types';

const Tabs: React.FC<TabsProps> = ({ tabs, activeTab, variant = 'standard', handleChange }) => {
  const a11yProps = (index: number) => {
    return {
      id: `tab-${index}`,
      'aria-controls': `tabpanel-${index}`,
    };
  };

  return (
    <Box sx={{ borderBottom: 1, borderColor: 'divider' }} mt={'40px'}>
      <StyledTabs value={activeTab} onChange={handleChange} variant={variant}>
        {tabs.map((tab, index) => (
          <Tab
            key={index}
            label={tab}
            disableRipple
            {...a11yProps(index)}
            cypress-id={`tab-${camelCase(tab)}`}
            sx={{ fontSize: '14px', textTransform: 'capitalize', textAlign: 'left' }}
          />
        ))}
      </StyledTabs>
    </Box>
  );
};

export default memo(Tabs);
