import { createTheme } from '@mui/material/styles';
import { bronz, error, grey, primary } from './colors';
import { shadows } from './shadows';

// Create a theme instance.
const theme = createTheme({
  typography: {
    fontFamily: 'Inter',
  },
  palette: {
    primary: {
      main: primary[600],
      light: primary[100],
      dark: primary[900],
      contrastText: primary[300],
    },
    error: {
      main: error[500],
    },
  },
  components: {
    MuiFormHelperText: {
      styleOverrides: {
        root: {
          marginLeft: '0px',
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          fontSize: 16,
          textTransform: 'capitalize',
          width: '100%',
          borderRadius: '8px',
          height: 44,
          boxShadow: '0px 1px 2px rgba(37, 23, 2, 0.05)',
          gap: '4px',
          '&.Mui-disabled': {
            backgroundColor: primary[100],
            color: '#fff',
          },
          '&:hover': {
            boxShadow: '0px 1px 2px rgba(6, 14, 50, 0.05), 0px 0px 0px 4px #D8E0F3',
          },
        },
        containedPrimary: {
          backgroundColor: primary[600],
          color: '#fff',
        },
        containedSecondary: {
          backgroundColor: bronz[600],
          color: '#fff',
          '&:hover': {
            backgroundColor: bronz[700],
          },
        },
        containedError: {
          backgroundColor: error[600],
          color: '#fff',
          '&:hover': {
            backgroundColor: error[700],
          },
        },
        containedInfo: {
          backgroundColor: primary[50],
          color: primary[700],
          '&:hover': {
            backgroundColor: primary[100],
          },
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          borderRadius: '8px',
          '& input': {
            height: '27px',
          },
          '&.Mui-focused': {
            boxShadow: '0px 1px 2px rgba(6, 14, 50, 0.05), 0px 0px 0px 4px #D8E0F3;',
            '& .MuiOutlinedInput-notchedOutline': {
              borderColor: grey[300],
            },
          },
        },
      },
    },
    MuiInputAdornment: {
      styleOverrides: {
        positionEnd: {
          '& svg': {
            fontSize: 14,
          },
        },
      },
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          maxWidth: 320,
          backgroundColor: grey[900],
          borderRadius: 8,
          padding: 12,
          fontSize: 14,
          lineHeight: '20px',
          fontWeight: 400,
          margin: '0 0 8px !important', // TODO: !important
        },
      },
    },
  },
  // cShadows => custom shadows (TODO: maybe need new naming)
  cShadows: shadows,
});

export default theme;
