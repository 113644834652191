import React from 'react';
// material-ui
import { Box, Typography } from '@mui/material';
// types
import { PageTitleProps } from './types';

const PageTitle: React.FC<PageTitleProps> = ({ icon, title }) => {
  return (
    <Box display='flex' alignItems='center'>
      {icon}

      <Typography variant='h5' fontSize={'30px'} fontWeight={600} ml={2}>
        {title}
      </Typography>
    </Box>
  );
};

export default PageTitle;
