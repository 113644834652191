import React, { lazy, useEffect, useState } from 'react';
// libs
import { Navigate, Route, Routes } from 'react-router-dom';
// routes
import routes from 'routes';
// components
import { Loadable } from 'components';
import PrivateRoute from 'components/PrivateRoute';
import ScrollToTop from 'components/ScrollToTop';
import AppLayout from 'Layouts/AppLayout';

const HomePage = Loadable(lazy(() => import('pages/Home')));
const SignUpPage = Loadable(lazy(() => import('pages/SignUp')));
const MyPropertyPage = Loadable(lazy(() => import('pages/MyProperty')));
const ResetPasswordPage = Loadable(lazy(() => import('pages/ResetPassword')));
const PublicDocumentsPage = Loadable(lazy(() => import('pages/PublicDocuments')));
const MyPropertySellingPage = Loadable(lazy(() => import('pages/MyProperty/Selling')));
const AssociationDocumentsPage = Loadable(lazy(() => import('pages/AssociationDocuments')));
const MyPropertyImprovementsPage = Loadable(lazy(() => import('pages/MyProperty/Improvements')));
const MyPropertyFinancialInfoPage = Loadable(lazy(() => import('pages/MyProperty/FinancialInfo')));
const MyPropertyCommunicationsPage = Loadable(lazy(() => import('pages/MyProperty/Communications')));
const MyPropertyComplainceIssuesPage = Loadable(lazy(() => import('pages/MyProperty/ComplainceIssues')));
const CommunityInformationPage = Loadable(lazy(() => import('pages/CommunityInformation')));
const CommunityInformationDetailsPage = Loadable(lazy(() => import('pages/CommunityInformation/Details')));
const DeviceRequestPage = Loadable(lazy(() => import('pages/DeviceRequest')));
const ContactUsPage = Loadable(lazy(() => import('pages/ContactUs')));
const CiaServicesPage = Loadable(lazy(() => import('pages/CiaServices')));
const BoardMemberPortalPage = Loadable(lazy(() => import('pages/BoardMemberPortal')));
const KeyPersonnelPage = Loadable(lazy(() => import('pages/KeyPersonnel')));
const AllAboutMePage = Loadable(lazy(() => import('pages/AllAboutMe')));
const LookLikeAnAccountPage = Loadable(lazy(() => import('pages/LookLikeAnAccount')));

const App: React.FC = () => {
  return (
    <ScrollToTop>
      <Routes>
        <Route element={<PrivateRoute />}>
          <Route path={routes.home} element={<AppLayout />}>
            <Route index element={<HomePage />} />

            <Route path={routes.myProperty.index} element={<MyPropertyPage />} />
            <Route path={routes.myProperty.selling} element={<MyPropertySellingPage />} />
            <Route path={routes.myProperty.financialInfo} element={<MyPropertyFinancialInfoPage />} />
            <Route path={routes.myProperty.communications} element={<MyPropertyCommunicationsPage />} />
            <Route path={routes.myProperty.improvementApps} element={<MyPropertyImprovementsPage />} />
            <Route path={routes.myProperty.complainceIssues} element={<MyPropertyComplainceIssuesPage />} />
            <Route path={routes.myProperty.complainceIssues} element={<MyPropertyComplainceIssuesPage />} />

            <Route path={routes.publicDocuments} element={<PublicDocumentsPage />} />

            <Route path={routes.associationDocuments} element={<AssociationDocumentsPage />} />

            <Route path={routes.communityInformation} element={<CommunityInformationPage />} />
            <Route path={`${routes.communityInformation}/:title`} element={<CommunityInformationDetailsPage />} />

            <Route path={routes.deviceRequest} element={<DeviceRequestPage />} />

            <Route path={routes.contactUs} element={<ContactUsPage />} />

            <Route path={routes.ciaServices} element={<CiaServicesPage />} />

            <Route path={routes.boardMemberPortal} element={<BoardMemberPortalPage />} />

            <Route path={routes.keyPersonnel} element={<KeyPersonnelPage />} />

            <Route path={routes.allAboutMe} element={<AllAboutMePage />} />

            <Route path={routes.lookLikeAnAccount} element={<LookLikeAnAccountPage />} />
          </Route>
        </Route>

        <Route path={routes.auth.signUp} element={<SignUpPage />} />

        <Route path={routes.auth.resetPassword} element={<ResetPasswordPage />} />

        <Route path='*' element={<Navigate to='/' />} />
      </Routes>
    </ScrollToTop>
  );
};

export default App;
