// materail-ui
import { styled } from '@mui/material';
// styles
import { blueLight, indigo, orange, pink } from 'themes/colors';

export const StatusLabel = styled('p')<any>`
  margin: 0;
  font-weight: 500;
  font-size: 14px;
  padding: 2px 10px;
  border-radius: 16px;

  ${({ status }) => [
    status === 'Approved' &&
      `
    background-color: ${indigo[50]};
    color: ${indigo[700]}
  `,
    status === 'Conditionally Approved' &&
      `
    background-color: ${blueLight[50]};
    color: ${blueLight[700]}
  `,
    status === 'More Info Needed' &&
      `
    background-color: ${pink[50]};
    color: ${pink[700]}
  `,
    status === 'Disapproved' &&
      `
    background-color: ${orange[50]};
    color: ${orange[700]}
  `,
  ]}
`;
