import React, { memo } from 'react';
// components
import ApplicationCard from './components/ApplicationCard';
import CommunicationCard from './components/CommunicationCard';
import DefaultCard from './components/DefaultCard';
import InvoiceCard from './components/InvoiceCard';
import LiensCard from './components/LiensCard';
import PaymentCard from './components/PaymentCard';
import ViolationActivityCard from './components/ViolationActivityCard';
import ViolationCard from './components/ViolationCard';
// styles
import { CardWrapper } from './styled';
import { grey, primary } from 'themes/colors';
// types
import { CardProps, PaymentCardProps } from './types';

const Card: React.FC<CardProps & PaymentCardProps> = (props) => {
  const isPaymentCard = props.variant === 'paymentPlan';

  const renderCard = () => {
    switch (props.variant) {
      case 'default':
        return <DefaultCard {...props} />;
      case 'invoice':
      case 'accountActivity':
        return <InvoiceCard {...props} />;
      case 'violation':
        return <ViolationCard {...props} />;
      case 'violationActivity':
        return <ViolationActivityCard {...props} />;
      case 'application':
        return <ApplicationCard {...props} />;
      case 'paymentPlan':
        return <PaymentCard {...props} />;
      case 'communication':
        return <CommunicationCard {...props} />;
      case 'liens':
        return <LiensCard {...props} />;

      default:
        break;
    }
  };
  return (
    <CardWrapper
      sx={{
        backgroundColor: isPaymentCard ? primary[50] : 'transparent',
        borderColor: isPaymentCard ? 'transparent' : grey[200],
      }}
    >
      {renderCard()}
    </CardWrapper>
  );
};

export default memo(Card);
